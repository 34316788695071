import PropTypes from 'prop-types';
import React from 'react';

function SeoLazy({ isOpen, items }) {
	if(!isOpen) return null;

	return (
		<div className='invisible'>
			{items.map(item => (
				React.createElement(item.tag, { key: item.id }, item.text)
			))}
		</div>
	);
}

SeoLazy.propTypes = {
	items: PropTypes.array.isRequired,
};

export default SeoLazy;
